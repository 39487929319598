var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-form",
        {
          attrs: { enctype: "multipart/form-data" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submitCreate.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "b-card",
            { staticClass: "mb-1", attrs: { "no-body": "" } },
            [
              _c("b-card-header", [
                _c(
                  "div",
                  [
                    _c("b-card-title", { staticClass: "mb-0" }, [
                      _vm._v(" Dados "),
                    ]),
                  ],
                  1
                ),
              ]),
              _c("b-card-body", { staticClass: "pl-2 pr-2" }, [
                _c("div", { staticClass: "form-row" }, [
                  _c("div", { staticClass: "col-md-6" }, [
                    _c("div", { staticClass: "form-group mb-md-0" }, [
                      _c("label", { attrs: { for: "" } }, [
                        _c("i", {
                          staticClass: "text-danger bi bi-record-circle",
                        }),
                        _vm._v(" Nome "),
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.item.company_name,
                            expression: "item.company_name",
                          },
                        ],
                        staticClass: "form-control",
                        class: {
                          "is-invalid": _vm.$v.item.company_name.$error,
                        },
                        attrs: { type: "text" },
                        domProps: { value: _vm.item.company_name },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.item,
                              "company_name",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "col-md-4" }, [
                    _c(
                      "div",
                      { staticClass: "form-group mb-md-0" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(" Unidade "),
                        ]),
                        _c(
                          "v-select",
                          {
                            attrs: {
                              label: "title",
                              "item-text": "title",
                              "item-value": "code",
                              searchable: false,
                              options: _vm.optionsUnits,
                            },
                            model: {
                              value: _vm.item.unit,
                              callback: function ($$v) {
                                _vm.$set(_vm.item, "unit", $$v)
                              },
                              expression: "item.unit",
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                attrs: { slot: "no-options" },
                                slot: "no-options",
                              },
                              [_vm._v(" Nenhum registro encontrado ")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "col-md-2" }, [
                    _c(
                      "div",
                      { staticClass: "form-group mb-md-0" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _c("i", {
                            staticClass: "text-danger bi bi-record-circle",
                          }),
                          _vm._v(" Situação "),
                        ]),
                        _c(
                          "v-select",
                          {
                            class: { "is-invalid": _vm.$v.item.active.$error },
                            attrs: {
                              label: "title",
                              "item-text": "title",
                              "item-value": "code",
                              searchable: false,
                              options: _vm.optionsStatus,
                            },
                            model: {
                              value: _vm.item.active,
                              callback: function ($$v) {
                                _vm.$set(_vm.item, "active", $$v)
                              },
                              expression: "item.active",
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                attrs: { slot: "no-options" },
                                slot: "no-options",
                              },
                              [_vm._v(" Nenhum registro encontrado ")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _c("div", { staticClass: "form-row justify-content-end" }, [
            _c("div", { staticClass: "col-md-2" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-block btn-success",
                  attrs: { type: "submit" },
                },
                [
                  _vm.submited
                    ? _c(
                        "div",
                        [
                          _c("b-spinner", {
                            attrs: { small: "", variant: "light" },
                          }),
                          _vm._v(" Verificando... "),
                        ],
                        1
                      )
                    : _c("div", [_vm._v("Cadastrar")]),
                ]
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }